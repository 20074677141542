import * as React from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';

import NoMatch from '../404Old';
import { PageLoaderFallback } from '../../layout/PageLoaderFallback';

const EiendomsmeglerBergen = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerBergen'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerLillestrom = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerLillestrom'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerOslo = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerOslo'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerStavanger = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerStavanger'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerVestland = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerVestland'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerVikoyr = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerVikoyr'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerVikoyr2 = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerVikoyr2'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerFinn = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerVikoyrFinn'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerVikoyrBrandtLokal = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerVikoyrBrandtLokal'),
  {
    fallback: <PageLoaderFallback />
  }
);

const EiendomsmeglerRomerike = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerRomerike'),
  {
    fallback: <PageLoaderFallback />
  }
);
const EiendomsmeglerJaeren = loadable(
  () => import(/* webpackPrefetch: true */ './EiendomsmeglerJaeren'),
  {
    fallback: <PageLoaderFallback />
  }
);
const VeienTilSolgt = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/VeienTilSolgt'),
  {
    fallback: <PageLoaderFallback />
  }
);

const VilkaarForVeienTilSolgt = loadable(
  () => import(/* webpackPrefetch: true */ './veientilsolgt/Terms'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Eiendomsmegler: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/eiendomsmegler/oslo" component={EiendomsmeglerOslo} />
      <Route
        exact
        path="/eiendomsmegler/lillestrom"
        component={EiendomsmeglerLillestrom}
      />
      <Route
        exact
        path="/eiendomsmegler/stavanger"
        component={EiendomsmeglerStavanger}
      />
      <Route
        exact
        path="/eiendomsmegler/bergen"
        component={EiendomsmeglerBergen}
      />
      <Route
        exact
        path="/eiendomsmegler/vestland"
        component={EiendomsmeglerVestland}
      />
      <Route
        exact
        path="/eiendomsmegler/vikoyrbrandt/midtsiden"
        component={EiendomsmeglerVikoyr}
      />
      <Route
        exact
        path="/eiendomsmegler/vikoyrbrandt/osogfusa"
        component={EiendomsmeglerVikoyr2}
      />
      <Route
        exact
        path="/eiendomsmegler/vikoyrbrandt/finn"
        component={EiendomsmeglerFinn}
      />
      <Route
        exact
        path="/eiendomsmegler/vikoyrbrandtLokal"
        component={EiendomsmeglerVikoyrBrandtLokal}
      />
      <Route
        exact
        path="/eiendomsmegler/romerike"
        component={EiendomsmeglerRomerike}
      />
      <Route
        exact
        path="/eiendomsmegler/jaeren"
        component={EiendomsmeglerJaeren}
      />
      <Route exact path="/eiendomsmegler/solgt" component={VeienTilSolgt} />
      <Route
        exact
        path="/eiendomsmegler/solgt/vilkaar"
        component={VilkaarForVeienTilSolgt}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Eiendomsmegler;
